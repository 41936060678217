<template>
  <div>
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="
        mode === 'add'
          ? $t('library.ajouterDocument')
          : $t('library.editDocument')
      "
      :width="1000"
      :footer="null"
    >
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item :label="$t('library.titre')">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: $t('requis.titre') }] },
            ]"
            :placeholder="$t('library.titre')"
          />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('library.subject')">
              <a-select
                mode="multiple"
                :placeholder="`${$t('library.select')} ${$t(
                  'library.subject'
                )}`"
                v-decorator="[
                  'subject',
                  {
                    rules: [{ required: true, message: $t('requis.subject') }],
                  },
                ]"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="subject in listSubjects"
                  :key="subject._id"
                  :value="subject._id"
                >
                  {{ subject.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('etablissement.niveau')">
              <a-select
                mode="multiple"
                :placeholder="`${$t('library.select')} ${$t(
                  'etablissement.niveau'
                )}`"
                v-decorator="[
                  'level',
                  {
                    rules: [
                      { required: true, message: $t('library.levelRequired') },
                    ],
                  },
                ]"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="level in levels[schoolType]"
                  :key="level.value"
                  :value="level.value"
                >
                  {{ level.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :label="$t('actualite.description')"
          class="quill-editor-container"
        >
          <quill-editor v-model="description" :options="editorOptions" />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('product.coverPicture')">
              <a-upload-dragger
                :file-list="coverPicture"
                :multiple="false"
                :custom-request="handleCustomRequest"
                :before-upload="beforeUploadCoverPicture"
                accept=".jpg,.jpeg,.png"
                list-type="picture"
                @change="handleChangeUploadCoverPicture"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t("product.uploadCoverPicture") }}
                </p>
                <p class="ant-upload-hint">{{ $t("actualite.cliquer") }}</p>
              </a-upload-dragger>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('library.attachment')">
              <a-upload-dragger
                :file-list="fileList"
                :before-upload="beforeUploadContent"
                :custom-request="handleCustomRequest"
                :multiple="true"
                @change="handleChangeUpload"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t("library.uploadAttachment") }}
                </p>
                <p class="ant-upload-hint">
                  {{ $t("actualite.cliquer") }}
                </p>
              </a-upload-dragger>
              <span
                style="color: #f5222e; font-size: 14px"
                v-if="documentUploaded"
                >{{ $t("library.docRequired") }}</span
              >
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-button
            type="primary"
            htmlType="submit"
            class="mr-3"
            :loading="loading"
            :disabled="disabled"
            >{{
              mode === "add" ? $t("library.submit") : $t("action.modifier")
            }}</a-button
          >
          <a-button type="default" @click="handleCancel" :disabled="disabled">{{
            $t("action.annuler")
          }}</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import "quill/dist/quill.snow.css";

Quill.register("modules/magicUrl", MagicUrl);

export default {
  name: "AddDigitalLibrary",
  components: {
    quillEditor,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  data() {
    return {
      visibleModal: false,
      form: this.$form.createForm(this),
      documentData: {},
      description: "",
      listSubjects: [],
      fileList: [],
      files: [],
      coverPicture: [],
      deletefiles: [],
      documentUploaded: false,
      loading: false,
      disabled: false,
      errorShown: false,
      schoolType: null,
      levels: {
        jardinEnfant: [
          { value: -2, label: this.$t("niveau.niveauPre") },
          { value: -1, label: this.$t("niveau.niveau0") },
        ],
        ecole: [
          { value: -2, label: this.$t("niveau.niveauPre") },
          { value: -1, label: this.$t("niveau.niveau0") },
          { value: 1, label: this.$t("niveau.niveau1") },
          { value: 2, label: this.$t("niveau.niveau2") },
          { value: 3, label: this.$t("niveau.niveau3") },
          { value: 4, label: this.$t("niveau.niveau4") },
          { value: 5, label: this.$t("niveau.niveau5") },
          { value: 6, label: this.$t("niveau.niveau6") },
        ],
        college: [
          { value: 7, label: this.$t("niveau.niveau7") },
          { value: 8, label: this.$t("niveau.niveau8") },
          { value: 9, label: this.$t("niveau.niveau9") },
        ],
        lycee: [
          { value: 10, label: this.$t("niveau.niveau10") },
          { value: 11, label: this.$t("niveau.niveau11") },
          { value: 12, label: this.$t("niveau.niveau12") },
          { value: 13, label: this.$t("niveau.niveau13") },
        ],
        collegeEtLycee: [
          { value: 7, label: this.$t("niveau.niveau7") },
          { value: 8, label: this.$t("niveau.niveau8") },
          { value: 9, label: this.$t("niveau.niveau9") },
          { value: 10, label: this.$t("niveau.niveau10") },
          { value: 11, label: this.$t("niveau.niveau11") },
          { value: 12, label: this.$t("niveau.niveau12") },
          { value: 13, label: this.$t("niveau.niveau13") },
        ],
        primaireEtCollege: [
          { value: -2, label: this.$t("niveau.niveauPre") },
          { value: -1, label: this.$t("niveau.niveau0") },
          { value: 1, label: this.$t("niveau.niveau1") },
          { value: 2, label: this.$t("niveau.niveau2") },
          { value: 3, label: this.$t("niveau.niveau3") },
          { value: 4, label: this.$t("niveau.niveau4") },
          { value: 5, label: this.$t("niveau.niveau5") },
          { value: 6, label: this.$t("niveau.niveau6") },
          { value: 7, label: this.$t("niveau.niveau7") },
          { value: 8, label: this.$t("niveau.niveau8") },
          { value: 9, label: this.$t("niveau.niveau9") },
        ],
      },
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
          ],
          magicUrl: true,
        },
      },
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    document: {
      immediate: true,
      handler(document) {
        if (document) {
          this.documentData = document;
          this.$nextTick(() => {
            this.form.setFieldsValue({
              title: document.title,
              subject: document.subject
                ? document.subject.map((item) => item._id)
                : [],
              level: document.level,
            });
            this.description = document.description || "";
            if (document.coverPicture) {
              this.coverPicture = [
                {
                  uid: "-1",
                  name: this.getAttachmentName(document.coverPicture),
                  status: "done",
                  url: document.coverPicture,
                  thumbUrl: document.coverPicture,
                },
              ];
            }
            if (document.files && Array.isArray(document.files)) {
              this.fileList = document.files.map((attachmentUrl) => ({
                url: attachmentUrl,
                name: this.getAttachmentName(attachmentUrl),
                uid: attachmentUrl,
                status: "done",
              }));
            }
          });
        }
      },
    },
  },
  created() {
    this.fetchOptions();
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
  },
  methods: {
    async fetchOptions() {
      try {
        const res = await apiClient.post("/subject/filter", {
          query: { status: "active" },
        });
        this.listSubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
      } catch (error) {
        this.$message.error(this.$t("error.aucMatiere"));
      }
    },
    getAttachmentName(url) {
      const parts = url.split("/");
      const fileNameWithType = parts[parts.length - 1];
      const decodedFileNameWithType = decodeURIComponent(fileNameWithType);
      const regex = /^(\d{13})-(.+)$/;
      const match = decodedFileNameWithType.match(regex);
      const extractedName = match ? match[2] : decodedFileNameWithType;

      const lastDotIndex = extractedName.lastIndexOf(".");
      const fileName =
        lastDotIndex !== -1
          ? extractedName.slice(0, lastDotIndex)
          : extractedName;
      const fileType =
        lastDotIndex !== -1 ? extractedName.slice(lastDotIndex + 1) : "";

      return `${fileName}.${fileType}`;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    beforeUploadCoverPicture(file) {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(
        file.type
      );
      if (!isImage) {
        this.$message.error(this.$t("library.fileUploadError"));
        return false;
      }
      this.coverPicture = [
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: URL.createObjectURL(file),
          thumbUrl: URL.createObjectURL(file),
          originFileObj: file,
        },
      ];
      return false;
    },
    beforeUploadContent(file, fileList) {
      if (!this.errorShown) {
        const totalFiles = fileList.length + this.fileList.length;
        if (totalFiles >= 15) {
          this.$message.error(this.$t("library.maxDocs"));
          this.errorShown = true;
          return false;
        }
      }
      this.fileList = [...this.fileList, file];
      this.files = [...this.files, file];
      return true;
    },
    handleChangeUpload(info) {
      const { status } = info.file;
      this.documentUploaded = false;
      this.disabled = status === "uploading";
      if (status === "done") {
      }
      if (status === "removed") {
        this.fileList = this.fileList.filter(
          (item) => item.uid !== info.file.uid
        );
        this.files = this.files.filter((item) => item.uid !== info.file.uid);
        if (this.mode === "edit") {
          const removedIndex = this.documentData.files.findIndex(
            (item) => item === info.file.url
          );
          if (removedIndex !== -1) {
            this.deletefiles.push(removedIndex);
          }
        }
      }
    },
    handleChangeUploadCoverPicture(info) {
      const { status } = info.file;
      if (status === "removed") {
        this.coverPicture = [];
      }
    },
    handleCustomRequest({ file, onSuccess }) {
      // Simulate uploading process
      setTimeout(() => {
        onSuccess("ok");
      }, 1000);
    },
    handleCancel() {
      this.form.resetFields();
      this.fileList = [];
      this.files = [];
      this.coverPicture = [];
      this.description = "";
      this.documentUploaded = false;
      this.visibleModal = false;
      this.documentData = {};
      this.deletefiles = [];
      this.$emit("close");
    },
    handleSubmit(e) {
      e.preventDefault();
      if (this.fileList.length === 0) {
        this.documentUploaded = true;
      }
      this.form.validateFields((err, values) => {
        if (!err && !this.documentUploaded) {
          if (this.mode === "add") {
            this.addDocument(values);
          } else {
            const allHaveUrl = this.fileList.every((file) => file.url);
            const updateCoverPicture =
              this.coverPicture.length !== 0
                ? this.coverPicture[0].url === this.documentData.coverPicture
                : true;
            if (allHaveUrl && updateCoverPicture) {
              this.editDocumentNoFiles(values);
            } else {
              this.editDocumentWithFiles(values);
            }
          }
        }
      });
    },
    addDocument(values) {
      const formData = new FormData();
      formData.append("title", values.title);
      values.subject.forEach((subject) => {
        formData.append("subject[]", subject);
      });
      values.level.forEach((level) => {
        formData.append("level[]", level);
      });
      if (this.description !== "") {
        formData.append("description", this.description);
      }
      if (this.coverPicture.length > 0) {
        formData.append("coverPicture", this.coverPicture[0].originFileObj);
      }
      for (const file of this.fileList) {
        const fileToUpload = file.originFileObj ? file.originFileObj : file;
        formData.append("files", fileToUpload);
      }
      this.loading = true;
      this.disabled = true;
      apiClient
        .put("library/item", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.$message.success(this.$t("success.libraryAddDoc"));
          this.$emit("documentAdded", res.data._id);
          this.handleCancel();
        })
        .catch((e) => {
          this.$message.error(this.$t("error.libraryAddDoc"));
        })
        .finally(() => {
          this.loading = false;
          this.disabled = false;
        });
    },
    editDocumentNoFiles(data) {
      const requestBody = {};
      if (data.title !== this.documentData.title) {
        requestBody.title = data.title;
      }
      if (data.subject) {
        const existingSubjectIds = this.documentData.subject.map((subject) =>
          subject._id.toString()
        );
        const newSubjectIds = data.subject.map((subject) => subject.toString());
        if (
          JSON.stringify(newSubjectIds) !== JSON.stringify(existingSubjectIds)
        ) {
          requestBody.subject = data.subject;
        }
      }
      if (data.level) {
        if (
          data.level.length !== this.documentData.level.length ||
          !data.level.every(
            (value, index) => value === this.documentData.level[index]
          )
        ) {
          requestBody.level = data.level;
        }
      }
      if (this.description !== this.documentData.description) {
        requestBody.description = this.description;
      }
      if (this.deletefiles.length !== 0) {
        requestBody.deletefiles = this.deletefiles;
      }
      requestBody.deleteCoverPicturePermanently =
        this.coverPicture.length === 0;
      requestBody.deleteCoverPicture =
        !requestBody.deleteCoverPicturePermanently
          ? this.documentData.coverPicture !== this.coverPicture[0].url
          : true;
      this.loading = true;
      this.disabled = true;

      const id = this.documentData._id;
      apiClient
        .patch(`library/item/${id}`, requestBody)
        .then((res) => {
          this.$message.success(this.$t("success.libraryUpdateDoc"));
          this.$emit("documentUpdated", this.documentData._id);
          this.handleCancel();
        })
        .catch((e) => {
          this.$message.error(this.$t("error.libraryUpdateDoc"));
        })
        .finally(() => {
          this.loading = false;
          this.disabled = false;
        });
    },
    editDocumentWithFiles(data) {
      const formData = new FormData();
      if (data.title !== this.documentData.title) {
        formData.append("title", data.title);
      }
      if (data.subject) {
        const existingSubjectIds = this.documentData.subject.map((subject) =>
          subject._id.toString()
        );
        const newSubjectIds = data.subject.map((subject) => subject.toString());
        if (
          JSON.stringify(newSubjectIds) !== JSON.stringify(existingSubjectIds)
        ) {
          formData.append("subject[]", data.subject);
        }
      }
      if (data.level) {
        if (
          data.level.length !== this.documentData.level.length ||
          !data.level.every(
            (value, index) => value === this.documentData.level[index]
          )
        ) {
          formData.append("level[]", data.level);
        }
      }
      if (this.description !== this.documentData.description) {
        formData.append("description", this.description);
      }
      if (this.deletefiles.length !== 0) {
        this.deletefiles.forEach((fileIndex) => {
          formData.append("deletefiles[]", fileIndex);
        });
      }
      if (this.coverPicture.length === 0) {
        formData.append("deleteCoverPicturePermanently", true);
      } else {
        formData.append("deleteCoverPicturePermanently", false);
      }
      formData.append(
        "deleteCoverPicture",
        this.coverPicture.length !== 0
          ? this.documentData.coverPicture !== this.coverPicture[0].url
          : true
      );
      const updateCoverPicture =
        this.coverPicture.length !== 0
          ? this.coverPicture[0].url !== this.documentData.coverPicture
          : false;
      if (updateCoverPicture) {
        formData.append(
          "coverPicture",
          this.coverPicture[0].originFileObj || this.coverPicture[0].url
        );
      }
      if (this.files) {
        for (const file of this.files) {
          const fileToUpload = file.originFileObj ? file.originFileObj : file;
          formData.append("files", fileToUpload);
        }
      }
      this.loading = true;
      this.disabled = true;
      const id = this.documentData._id;
      apiClient
        .patch(`library/item/${id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.$message.success(this.$t("success.libraryUpdateDoc"));
          this.$emit("documentUpdated", this.documentData._id);
          this.handleCancel();
        })
        .catch((e) => {
          this.$message.error(this.$t("error.libraryUpdateDoc"));
        })
        .finally(() => {
          this.loading = false;
          this.disabled = false;
        });
    },
  },
};
</script>

<style>
.switch-container {
  display: flex;
  align-items: center;
}

.switch-container p {
  margin-left: 10px;
  margin-bottom: 0;
}

.ql-snow .ql-tooltip {
  z-index: 3 !important;
}
</style>
