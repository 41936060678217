<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col d-flex">
          <a-button icon="plus" class="mr-4 w-25" @click="openModal(null)">
            {{ $t("library.ajouterDocument") }}
          </a-button>
          <a-input
            :placeholder="$t('library.search')"
            @change="handleSearchTitle"
            class="w-25"
          />
        </div>
        <div class="col-auto d-flex align-items-center justify-content-end">
          <a-switch
            default-checked
            :checked-children="$t('utilisateur.active')"
            :un-checked-children="$t('utilisateur.nonActive')"
            :checked="!showArchived"
            @change="showArchived = !showArchived"
          />
        </div>
      </div>
      <a-table
        :loading="tableLoading"
        :rowKey="'_id'"
        tableLayout="large"
        :columns="columns"
        :data-source="filteredData"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        class="w-100 p-0 m-0"
      >
        <template slot="coverPicture" slot-scope="text, record">
          <img
            v-if="record.coverPicture"
            :src="record.coverPicture"
            style="width: 50px; height: 50px"
          />
        </template>
        <template slot="createdBy" slot-scope="text, record">
          <span class="mr-2">{{ record.createdBy.name }}</span>
          <a-tag :color="record.createdBy.role === 'admin' ? 'blue' : 'purple'">
            {{
              record.createdBy.role === "admin"
                ? $t("autorisation.admin")
                : $t("emploi.enseignant")
            }}
          </a-tag>
        </template>
        <template slot="createdAt" slot-scope="text, record">
          {{ moment(record.createdAt).format("YYYY/MM/DD HH:mm") }}
        </template>
        <template slot="updatedAt" slot-scope="text, record">
          {{ moment(record.updatedAt).format("YYYY/MM/DD HH:mm") }}
        </template>

        <template slot="actions" slot-scope="text, record">
          <a-button
            class="mr-3"
            @click="
              () => $router.push('/degital_library/document/' + record._id)
            "
            ><a-icon type="eye" />{{ $t("action.voir") }}
          </a-button>
          <span v-if="!showArchived">
            <a-button class="mr-3" @click="openModal(record)" type="primary">
              <a-icon type="edit" />{{ $t("action.modifier") }}
            </a-button>
          </span>
          <span v-if="!showArchived">
            <a-popconfirm
              :title="$t('requis.supp')"
              @confirm="() => deleteDocument(record._id)"
            >
              <a-button type="danger">
                <a-icon type="delete" /> {{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
          </span>
          <span v-if="showArchived">
            <a-popconfirm
              :title="$t('all.sureTo')"
              @confirm="reactivateDocument(record._id)"
            >
              <a-button>
                <a-icon type="plus" />
                <span style="margin-left: 8px">{{ $t("liste.reactive") }}</span>
              </a-button>
            </a-popconfirm>
          </span>
        </template>

        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')}  ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
        </template>
      </a-table>
    </div>
    <DocumentForm
      :visible="showModal"
      :mode="mode"
      :document="selectedDocument"
      @close="handleCancel"
      @documentAdded="documentAdded"
      @documentUpdated="documentUpdated"
    />
  </div>
</template>

<script>
import DocumentForm from "@/views/degital_library/components/DigitalLibraryForm.vue";
import apiClient from "@/services/axios";
import moment from "moment";
export default {
  components: {
    DocumentForm,
  },
  data() {
    return {
      tableLoading: false,
      moment,
      allData: [],
      knownFiles: {
        pdf: ["pdf"],
        image: ["png", "jpg"],
        word: ["doc", "docx"],
        excel: ["xlsx", "xls"],
        ppt: ["pptx", "pptm"],
        video: ["mp4", "mkv", "mov", "avi"],
      },
      searchTerm: "",
      showArchived: false,
      showModal: false,
      mode: "add",
      selectedDocument: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t("product.coverPicture"),
          dataIndex: "coverPicture",
          key: "coverPicture",
          scopedSlots: { customRender: "coverPicture" },
        },
        {
          title: this.$t("library.titre"),
          dataIndex: "title",
          key: "title",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "title",
          },
          sorter: (a, b) => a.title.localeCompare(b.title),
          onFilter: (value, record) =>
            record.title.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("library.createdBy"),
          dataIndex: "createdBy",
          key: "createdBy",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "createdBy",
          },
          sorter: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
          onFilter: (value, record) =>
            record.createdBy.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("library.createdAt"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        },
        {
          title: this.$t("library.lastUpdate"),
          dataIndex: "updatedAt",
          key: "updatedAt",
          scopedSlots: { customRender: "updatedAt" },
          sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        },
        {
          title: this.$t("action.actions"),
          key: "actions",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ];
    },
    data() {
      return this.allData
        .filter((val) =>
          this.showArchived
            ? val.status === "inactive"
            : val.status === "active"
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    filteredData() {
      if (!this.searchTerm) {
        return this.data;
      }
      return this.data.filter((item) =>
        item.title.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.tableLoading = true;
      try {
        const response = await apiClient.post("/library/item/filter", {});
        this.allData = response.data;
      } catch (error) {
      } finally {
        this.tableLoading = false;
      }
    },
    openModal(data) {
      this.mode = data ? "edit" : "add";
      this.selectedDocument = data || null;
      this.showModal = true;
    },
    handleCancel() {
      this.mode = "add";
      this.selectedDocument = null;
      this.showModal = false;
    },
    async deleteDocument(id) {
      try {
        await apiClient.patch(`library/item/archive/${id}`, {
          status: "inactive",
        });
        this.allData = this.allData.map((elem) =>
          elem._id === id ? { ...elem, status: "inactive" } : elem
        );
        this.$message.success(this.$t("success.libraryArchiveDoc"));
      } catch (error) {
        this.$message.error(this.$t("error.libraryArchiveDoc"));
      }
    },
    async reactivateDocument(id) {
      try {
        await apiClient.patch(`library/item/archive/${id}`, {
          status: "active",
        });
        this.allData = this.allData.map((elem) =>
          elem._id === id ? { ...elem, status: "active" } : elem
        );
        this.$message.success(this.$t("success.libraryReactiveDoc"));
      } catch (error) {
        this.$message.error(this.$t("error.libraryReactiveDoc"));
      }
    },
    documentUpdated(id) {
      apiClient
        .get(`library/item/${id}`)
        .then((res) => {
          const index = this.allData.findIndex((doc) => doc._id === id);
          if (index !== -1) {
            this.$set(this.allData, index, res.data);
          }
        })
        .catch((e) => {})
        .finally(() => {});
    },
    documentAdded(id) {
      apiClient
        .get(`library/item/${id}`)
        .then((res) => {
          this.allData.unshift(res.data);
        })
        .catch((e) => {})
        .finally(() => {});
    },
    handleSearchTitle(e) {
      this.searchTerm = e.target.value;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
  },
};
</script>
